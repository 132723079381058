<template>
	<v-card>
		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-toolbar dense>
						<v-toolbar-title>
							<v-row style="margin-top: 10px">
								<v-col cols="12" style="display: inline-flex;">
									<v-chip
										style="margin: 0px"
										small
										:color="'info'"
									>
										{{ "Ingreso QR" }} </v-chip
									><!-- <span>
										<v-switch label="Salida" v-model="Exit">
										</v-switch>
									</span> -->
								</v-col>
							</v-row>
						</v-toolbar-title>
					</v-toolbar>
					<v-card dense>
						<v-row justify="center" style="margin:auto" v-if="objChamber !== null">
							<v-col
								align="center"
								cols="12"
								lg="12"
								md="12"
							>
								<s-scanner-qr
									:config="configScann"
									return-object
									:autofocus="false"
									@onValue="onValue($event)"
									:readonly="false"

								></s-scanner-qr>
							</v-col>
						</v-row>
						<v-row style="margin:auto" justify="center">

							<v-col cols="12">
								<s-select-definition 
                  :def="1345" 
                  label="Cámara" 
                  return-object
                  v-model="objChamber"
                >
                </s-select-definition>
							</v-col>

						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

			<v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
				<v-card color="primary" dark>
					<v-card-text v-if="messageProcessing.length == 0">
					Por favor espere
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
	</v-card>
</template>

<script>
	import sTunnelMaduration from "@/services/FrozenProduction/TunnelMadurationService";
	import _sMaturationTunnelPallet from "@/services/FrozenProduction/MaturationTunnelPalletService";
	import signalr from "signalr";
  import _sBoardingChamber from "@/services/FrozenProduction/BoardingChamberService";
  
	export default {
		components: {},
		data() {
			return {
				
				configScann: {},
				item: {},
				itemsChamber: [],
				objChamber: null,
				processing:false,
				messageProcessing: "",
			};
		},

		watch: {
		},

		created() {
			this.initialize()
		},

		methods: {

			initialize(){
				sTunnelMaduration.listChamber({}, this.$fun.getUserID()).then(r => {
					if(r.status == 200){

						console.log("al levantar",r.data);

						r.data.forEach(element => {
							element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.CchCapacity)
							element.ParihuelaAvailableUnit =   parseInt(element.CchCapacity)- parseInt(element.inParihuelaUsage)

							/* element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.CchCapacity)
							element.ParihuelaAvailableUnit =   parseInt(element.CchCapacity)- parseInt(element.inParihuelaUsage)
							
							if(element.ParihuelaUsagePorcentaje == 100){
								element.inColor = 'error'
							}

							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 50 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 50){
								element.inColor = 'success'
							} */
	
						});

						this.itemsChamber = r.data;
						

					}
					
				});
			},

      searchExpData(ExpID)
      {
        var message = "";
        _sBoardingChamber
        .search(ExpID, this.$fun.getUserID())
        .then(resp => {
          if(resp.status == 200){
            if(resp.data != null){
              this.item = resp.data;
              message = "Parihuela recogida correctamente";
              //this.item.UsrUpdateID = this.$fun.getUserID();
            }
            else{
                this.item = {
                  FbcID: 0,
                  ExpID: ExpID,
                  FbcDate: new Date(),
                  FbcExit: 1,
                  TypeChamber: this.objChamber.DedValue,
                  TypeDestiny: 3,
                  UsrCreateID: this.$fun.getUserID(),
                  UsrUpdateID: this.$fun.getUserID()
                };
                message = "Parihuela ingresada correctamente";
            }
            this.save(message);
          }
        })
      },

			onValue(val) {
        this.save(val);
			},

			save(ExpID) {
        this.item = {
          FbcID: 0,
          ExpID: ExpID,
          FbcDate: new Date(),
          FbcExit: 1,
          TypeChamber: this.objChamber.DedValue,
          TypeDestiny: 3,
          UsrCreateID: this.$fun.getUserID(),
          UsrUpdateID: this.$fun.getUserID()
        };

        this.processing = true;
        _sBoardingChamber
          .save(this.item, this.$fun.getUserID())
          .then(resp => {
            if (resp.status == 200) {
              this.processing = false;
              this.$fun.alert("Parihuela ingresada correctamente", "success");
              this.initialize()
            }
          },
          (e) => {
            this.processing = false;
          });
				
			},

		},

		mounted() {
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.FrozenEntryParihuelaIntunnel = group => {
					this.initialize()
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "frozenUpdateWaitingEntryParihuelaIntunnel");
				});
			});
		},
	};
</script>