import Service from "../Service";

const resource = "boardingChamber/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    listpalletoutput(requestID) {
        return Service.post(resource + "listpalletoutput", {}, {
            params: { requestID }
        })
    },


    search(ExpID, requestID) {
        return Service.post(resource + "search", {}, {
            params: { ExpID, requestID }
        })
    },


    listchamber(requestID) {
        return Service.post(resource + "listchamber", {}, {
            params: { requestID }
        })
    },


    list(obj, requestID) {
        return Service.post(resource + "list", obj, {
            params: { requestID }
        })
    },


    listmovimients(obj, requestID) {
        return Service.post(resource + "listmovimients", obj, {
            params: { requestID }
        })
    },


};